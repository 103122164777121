import React from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/events'
import MainLayout from '@root/src/components/layout/Main'
import sliceMapping from '@src/data/prismic-slice-mapping/event-slices'
import { GlobalDataProvider } from '@src/data/global-data'
import useGetEvents from '@root/src/hooks/useGetFutureAndPastEvents'
import { EventSlider } from '@cmp/site/CardSlider'
import { Helmet } from 'react-helmet'
import ArticleFootnote from '@cmp/site/ArticleFootnote'

const Slices = makeSlices(sliceMapping)

type Props = { data: { staticData: any } }

const Events = ({ data: staticData }: Props) => {
  const data = usePreview(staticData)

  const language = data.prismicEvents.lang.substring(0, 2)

  const { prismicEvents } = data

  const { futureEvents } = useGetEvents()

  const otherEvents = futureEvents.filter(
    n => n.prismicId !== prismicEvents.prismicId
  )

  return (
    <>
      <GlobalDataProvider language={language}>
        <MainLayout>
          <article className='page-wrap news'>
            <Slices body={prismicEvents.data.body} />
            <ArticleFootnote shareText='Ef þér leist vel á þennan viðburð þætti okkur vænt um ef þú deildir honum' />
            {otherEvents.length > 0 && (
              <EventSlider
                cards={otherEvents}
                title='Aðrir viðburðir'
                link_text='Sjá alla viðburði'
                link_url='/vidburdir'
              />
            )}
          </article>
        </MainLayout>
      </GlobalDataProvider>
      <Helmet>
        <title>
          {prismicEvents.data.title.text
            ? `${prismicEvents.data.title.text} | `
            : ''}
          Grænvangur
        </title>
      </Helmet>
    </>
  )
}

export default Events

export const query = graphql`
  query genericEventsQuery($prismicId: ID!) {
    prismicEvents(prismicId: { eq: $prismicId }) {
      ...prismicEventsFragmentFull
    }
  }
`
