import React, { FC } from 'react'
import {
  Slider,
  Card,
  CardDetailsType,
  ButtonDep as Button
} from '@islandsstofa/react'
import { beautifyDate } from '@islandsstofa/react/src/utils/date'
import { Link } from 'gatsby'
import cn from 'classnames'
import useGetEvents from '@root/src/hooks/useGetFutureAndPastEvents'

interface Props {
  title?: string
  link_text?: string
  link_url?: string
  cards?: CardDetailsType[]
}

const EventSlider: FC<Props> = ({
  title = 'Missing title',
  link_text = 'Missing link text',
  link_url = '/',
  cards
}) => {
  // if cards are provided then use those... otherwise fetch all
  const events = cards ?? useGetEvents()
  return (
    <Slider
      title={title}
      onRenderLink={() => (
        <Link to={link_url}>
          <Button
            className='d-none d-lg-flex align-items-end'
            type='secondary'
            icon='arrowRight'
            label={link_text}
          />
        </Link>
      )}
      cards={events}
      onRender={card => (
        <Link key={card.url} to={card.url ?? '/'} className='card--gap'>
          <Card
            containerClass='card card--slider'
            card={card}
            image={card.image ?? { url: '', alt: '' }}
            footnoteExtraStyle={
              {
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              } as React.CSSProperties
            }
            onRenderFootnote={(item, x) => (
              <span className={cn('parag--sm', x > 0 && 'pr-2')} key={x}>
                {(x === 0 ? 'Frá  ' : 'Til  ') +
                  beautifyDate(new Date(item), { short: true })}
              </span>
            )}
          />
        </Link>
      )}
    />
  )
}

export default EventSlider
