import React, { FC } from 'react'
import useGetNews from '@src/hooks/useGetNews'
import {
  Slider,
  Card,
  CardDetailsType,
  ButtonDep as Button
} from '@islandsstofa/react'
import { beautifyDate } from '@islandsstofa/react/src/utils/date'
import { Link } from 'gatsby'

interface Props {
  title?: string
  link_text?: string
  link_url?: string
  cards?: CardDetailsType[]
}

const NewsSlider: FC<Props> = ({
  title = 'Missing title',
  link_text = 'Missing link text',
  link_url = '/',
  cards
}) => {
  // if cards are provided then use those... otherwise fetch all
  const news = cards ?? useGetNews()

  return (
    <Slider
      title={title}
      onRenderLink={() => (
        <Link to={link_url}>
          <Button
            className='d-none d-lg-flex align-items-end'
            type='secondary'
            icon='arrowRight'
            label={link_text}
          />
        </Link>
      )}
      cards={news}
      onRender={card => (
        <Link key={card.url} to={card.url ?? '/'} className='card--gap'>
          <Card
            containerClass='card--slider'
            card={card}
            image={card.image}
            onRenderFootnote={(item, x) => (
              <span className='parag--sm' key={x}>
                {beautifyDate(new Date(item))}
              </span>
            )}
          />
        </Link>
      )}
    />
  )
}

export default NewsSlider
