const months = [
  'janúar',
  'febrúar',
  'mars',
  'apríl',
  'maí',
  'júní',
  'júlí',
  'ágúst',
  'september',
  'október',
  'nóvember',
  'desember'
]
export const beautifyDate = (d: Date, options?: { short: boolean }) => {
  const month = options?.short ? months[d.getMonth()].slice(0, 3) : months[d.getMonth()]
  return `${d.getDate()}. ${month} ${d.getFullYear()}`
}

const locale = 'en-GB'

export const getFormattedDate = (date: string) => {
  if (!date) return null
  const month = new Date(date).toLocaleString(locale, { month: 'short' })
  const dateSplit = date.split('-')
  return `${parseInt(dateSplit[2])} ${month} ${dateSplit[0]}`
}

export const getDateRange = (date1: string, date2: string) => {
  let date1Split = date1.split(' ')
  let date2Split = date2.split(' ')
  let dateStr1 = ''
  let dateStr2 = ''
  if (date1Split[2] === date2Split[2]) {
    if (date1Split[1] === date2Split[1]) {
      if (date1Split[0] === date2Split[0]) {
        return date1
      } else {
        dateStr1 = date1Split[0]
        dateStr2 = date2Split[0]
      }
      return `${dateStr1} - ${dateStr2} ${date1Split[1]} ${date1Split[2]}`
    } else {
      dateStr1 = date1Split[0] + ' ' + date1Split[1]
      dateStr2 = date2Split[0] + ' ' + date2Split[1]
    }
    return `${dateStr1} - ${dateStr2} ${date1Split[2]}`
  } else {
    dateStr1 = date1Split.join(' ')
    dateStr2 = date2Split.join(' ')
  }

  return `${dateStr1} - ${dateStr2}`
}

export const formatDate = (firstDate: string, secondDate?: string) => {
  const firstD = getFormattedDate(firstDate)
  if (!firstD) return ''
  if (!secondDate) {
    return firstD
  } else {
    const secondD = getFormattedDate(secondDate)
    if (!secondD) return ''
    return getDateRange(firstD, secondD)
  }
}
