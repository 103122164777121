import { graphql, useStaticQuery } from 'gatsby'
import '@src/data/prismic-fragments/events'
import { CardDetailsType } from '@islandsstofa/react'
import { sortEventsToFutureAndPast } from '../utils/dates'

export type EventCardDetailsType = CardDetailsType & {
  to: string
  from: string
}

export default () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicEvents(sort: { fields: data___from, order: DESC }) {
        nodes {
          ...prismicEventsFragmentFull
        }
      }
    }
  `)

  const events = data.allPrismicEvents.nodes.map((node: any) => {
    return {
      prismicId: node.prismicId,
      url: node.url,
      from: node.data.from,
      to: node.data.to,
      tags: [node.data.from, node.data.to],
      name: node.data.title,
      excerpt: node.data.subtitle,
      image: node.data.image
    }
  })

  return sortEventsToFutureAndPast(events)
}
