import { graphql, useStaticQuery } from 'gatsby'
import '@src/data/prismic-fragments/news'
import { CardDetailsType } from '@islandsstofa/react'

export default () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicNews(sort: { fields: data___date, order: DESC }) {
        nodes {
          ...prismicNewsFragmentFull
        }
      }
    }
  `)

  const newsCards: CardDetailsType[] = data.allPrismicNews.nodes.map(
    (node: any) => {
      return {
        prismicId: node.prismicId,
        url: node.url,
        image: node.data.image,
        tags: [node.data.date],
        name: node.data.title,
        excerpt: node.data.excerpt
      }
    }
  )

  return newsCards
}
