import { IconType } from '@islandsstofa/react'

export const getSocialShareLink = (type: IconType, href: string) => {
  switch (type) {
    case 'facebook':
      return 'https://www.facebook.com/sharer/sharer.php?u=' + href
    case 'linkedIn':
      return 'https://www.linkedin.com/shareArticle?mini=true&url=' + href
    case 'twitter':
      return 'https://twitter.com/intent/tweet?url=' + href
    default:
      return ''
  }
}
