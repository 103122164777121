import React from 'react'
import { IconButton, IconType } from '@islandsstofa/react'
import { getSocialShareLink } from '@src/utils'
import { useLocation } from '@reach/router'
import cn from 'classnames'

interface Props {
  shareText?: string
}

const socials: IconType[] = ['facebook', 'twitter', 'linkedIn']

const Share: React.FC = ({ children }) => {
  const shareableURL =
    typeof window !== 'undefined' ? new URL(useLocation().href).href : ''
  return (
    <div
      className={cn(
        'footnote__share d-flex justify-content-center align-items-center'
      )}
    >
      <div>
        <p className='mb-2'>{children}</p>
        <div className='d-flex justify-content-center'>
          {socials.map((s, i) => (
            <a
              key={i}
              href={getSocialShareLink(s, shareableURL)}
              target='_blank'
            >
              <IconButton type={s} className='footnote__share__icon mr-2 p-1' />
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

const ArticleFootnote = ({
  shareText = 'Ef þér leist vel á þessa frétt þætti okkur vænt um ef þú deildir henni'
}: Props) => {
  return (
    <div
      className={cn('footnote container page-slice-spacer footnote--single')}
    >
      <div className='col-lg-8 offset-lg-2 p-0'>
        {shareText && <Share>{shareText}</Share>}
      </div>
    </div>
  )
}

export default ArticleFootnote
