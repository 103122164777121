import { EventCardDetailsType } from '../hooks/useGetFutureAndPastEvents'

export const getYesterdayTime = () => {
  const today = new Date()
  const yesterday = today.setDate(today.getDate() - 1)
  return new Date(yesterday).getTime()
}

export const sortEventsToFutureAndPast = (events: EventCardDetailsType[]) => {
  const limit = getYesterdayTime()
  const getStartTime = (event: EventCardDetailsType) =>
    new Date(event.from).getTime()
  const getEndTime = (event: EventCardDetailsType) =>
    new Date(event.to).getTime()

  // Earlies to latest
  const futureEvents = events
    .filter(event => getEndTime(event) >= limit)
    .sort(
      (eventA: EventCardDetailsType, eventB: EventCardDetailsType) =>
        getStartTime(eventA) - getStartTime(eventB)
    )

  // Latest to oldest
  const passedEvents = events
    .filter(event => getEndTime(event) < limit)
    .sort(
      (eventA: EventCardDetailsType, eventB: EventCardDetailsType) =>
        getStartTime(eventB) - getStartTime(eventA)
    )

  return { futureEvents: [...futureEvents], pastEvents: [...passedEvents] }
}
